import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from "react-router-dom";
import Scroll from 'react-scroll'
import logo from './enorasilogo.png'
import { HashLink } from "react-router-hash-link";
// reactstrap components
import {
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import FontSizeChanger from 'react-font-size-changer';



const ScrollLink = Scroll.ScrollLink
function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
        
          <div className="navbar-translate">
            <NavbarBrand>
            <HashLink smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none'}} to='/'><img src={logo} alt='enorasi-logo' style={{width:'160px',padding:'0px 5px'}}></img></HashLink>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-label='navbar'
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
            <NavItem>
                <NavLink style={{fontSize:'12px'}}>
                <HashLink aria-label='Aρχική' id='up' smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none'}} to='/#landing'>Αρχική</HashLink>
                </NavLink>
              </NavItem>
            
              
              <NavItem>
                <NavLink style={{fontSize:'12px'}}>
                <HashLink aria-label='Gallery' id='up' smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none'}} to='/gallery'>Gallery</HashLink>
                </NavLink>
              </NavItem>
             
              <NavItem>
              <NavLink  style={{fontSize:'12px'}}>           
                <HashLink aria-label='Eπικοινωνία' id='up' smooth offset={() => 100} style={{fontSize:'12px',textDecoration:'none'}} to='/#contact'>Επικοινωνία</HashLink>
                </NavLink>
              </NavItem>
              <NavItem>
              <NavLink  style={{fontSize:'12px'}}>    
              <FontSizeChanger
          targets={['#up']}
          onChange={(element, newValue, oldValue) => {
            console.log(element, newValue, oldValue);
          }}
          options={{
            stepSize: 2,
            range: 3
          }}
          customButtons={{
            up: <span style={{'fontSize': '24px',top:'-6px',position:'relative'}}>A</span>,
            down: <span style={{'fontSize': '16px',top:'-6px',position:'relative'}}>A</span>,
            style: {
              backgroundColor: '#165473',
              color: '#dadada',
              border: "1px solid #dadada",
              WebkitBoxSizing: 'border-box',
              WebkitBorderRadius: '10px',
              width: '30px',
              height:'30px'
             
            },
            buttonsMargin: 10
          }}          
        />
         </NavLink>    
         </NavItem>
            
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
