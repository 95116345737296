/*eslint-disable*/
import React from "react";
import logo from "../../assets/img/tubers_logo.svg"
// reactstrap components
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'


function DarkFooter() {
  return (
    <footer className="footer">
    
   
    <div class="bg-light py-4" id='footer'>
      <div class="container text-center">
        <p style={{color:'whitesmoke',fontSize:'12px',textAlign:'justify'}}>Το έργο υλοποιείται στο πλαίσιο της Δράσης ΕΡΕΥΝΩ – ΔΗΜΙΟΥΡΓΩ – ΚΑΙΝΟΤΟΜΩ 16971 του Ταμείου Ανάκαμψης και Ανθεκτικότητας με τη χρηματοδότηση της Ευρωπαϊκής Ένωσης – NextGenerationEU (κωδικός έργου: ΤΑΕΔΚ-06172)</p>
 <hr/>
        <p class=" mb-0 py-2" style={{color:'#dadada',fontSize:'14px'}}>© 2023 ΕΝΟΡΑΣΗ</p>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/