import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Mapchart from '../map.js'
import signature from "../signaturejs.js";
import CookieConsent, { Cookies } from "react-cookie-consent";
import ContactForm from './contact'
import DarkFooter from "../../components/Footers/DarkFooter.js";
import cons from '../cons.png'
import test from '../../assets/img/test2.svg'
import { HashLink } from "react-router-hash-link";
import CustomForm from '../../components/SubscribeForm'
import episei from "../../assets/img/logos/episei2.png"
import castalia from "../../assets/img/logos/castalia.png"
import twi from "../../assets/img/logos/Twi.png"
import ipto from "../../assets/img/logos/ipto.png"
import FontSizeChanger from 'react-font-size-changer';



// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import MailchimpSubscribe from "react-mailchimp-subscribe"


const url = "https://gmail.us14.list-manage.com/subscribe/post?u=047540e6f745f9512f7cbb36e&amp;id=a0467af439&amp;f_id=00faf4e0f0";

const SimpleForm = () => <MailchimpSubscribe url={url}/>

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
        <LandingPageHeader/>
       
        <section className="section section-about-us" id='mission'>
       
 
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" id='up' style={{color:'#0e3b5b'}}>Το Πρόβλημα</h2>
                <p id='up' className="description" style={{textAlign:'justify',color:'#07353d'}}>
                <p id='up' style={{fontSize:'15px',textAlign:'justify',color:'#07353d'}}>
                Η <span id='bold'>εύρυθμη λειτουργία </span> των Κέντρων Υπερυψηλής Τάσης (ΚΥΤ) και των Υποσταθμών (ΥΣ) είναι <span id='bold'>ζωτικής σημασίας</span> για την αδιάκοπη
                 μεταφορά και διανομή του πολύτιμου αγαθού της ηλεκτρικής ενέργειας.</p>
<p style={{fontSize:'15px'}}>
Η μη προβλέψιμη αστοχία υλικού ή/και αστοχίες οφειλόμενες σε επίδραση 
ταχέων μεταβατικών φαινομένων έχει ως αποτέλεσμα την <span id='bold'>αστοχία εξοπλισμού </span>
 που οδηγεί σε διακοπή ρεύματος σε μεγάλα τμήματα της χώρας. 
</p>
<p style={{fontSize:'15px'}}>
Αποτελεί κοινή διαπίστωση των ειδικών για τη συντήρηση του 
εν λόγω εξοπλισμού ότι τέτοιου τύπου βλάβες μπορεί εν γένει να 
αποφευχθούν εάν ήταν διαθέσιμα <span id='bold'>τεχνικά μέσα</span> για τη <span id='bold'>συστηματική παρατήρηση </span>
 της κατάστασης του εξοπλισμού και την <span id='bold'>έγκαιρη διάγνωση</span> ενδεχόμενων αστοχιών υλικού.</p>
               
                </p>
              </Col>
            </Row>
            </Container>
            </section>
            <section className="section section-about-us" id='consortium' >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 id='up' className="title" style={{color:'white'}}>Ερευνητικό Έργο ΕΝΟΡΑΣΗ</h2>
                <p className="description" style={{textAlign:'justify',color:'white'}}/>
                <p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
                <span id='bold'>Η απεικόνιση της 
                θερμοκρασίας</span> των ηλεκτρολογικών στοιχείων θεωρείται ο αποτελεσματικότερος 
                τρόπος για τη <span id='bold'>διάγνωση</span> και τον <span id='bold'>εντοπισμό βλαβών</span>. Παρ' όλη την αναγκαιότητα, 
                η συχνή και συστηματική παρακολούθηση της θερμοκρασίας του καίριου εξοπλισμού 
                απαιτεί εξειδικευμένο προσωπικό και πολύ χρόνο για να καλυφθούν όλες οι 
                σχετικές εγκαταστάσεις. Συνεπώς, για την συντήρηση βάσει παρατήρησης της κατάστασης
                 απαιτείται η χρήση νέας προηγμένης τεχνολογίας που επιτυγχάνει αυτοματοποιημένη 
                 εκτέλεση μετρήσεων, λήψης και ανάλυσης δεδομένων. 
</p>
<p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
  Τη λύση μπορεί να προσφέρει ένα <span id='bold'>αυτόνομο κινούμενο ρομποτικό όχημα</span> το οποίο έχει τη δυνατότητα 
  για αυτοματοποιημένη επιθεώρηση χρησιμοποιώντας σύστημα οπτικής και θερμικής κάμερας. 
  Το κινούμενο ρομπότ θα <span id='bold'>καταγράφει τα 
καίρια ηλεκτρολογικά στοιχεία</span> ενός ΚΥΤ διενεργώντας αξιόπιστες περιοδικές μετρήσεις. </p>
<p id='up' style={{fontSize:'15px',color:'whitesmoke',textAlign:'justify'}}>
Το προτεινόμενο σύστημα θα επιτρέψει τη διεξαγωγή <span id='bold'>συχνών</span> και <span id='bold'>πλήρως αυτοματοποιημένων</span> επιθεωρήσεων 
της κατάστασης του εξοπλισμού και θα οδηγήσει στα ακόλουθα οικονομικό, διαχειριστικά και πληροφοριακό οφέλη:
</p>
<br/>
<ul id='up' style={{color:'whitesmoke',textAlign:'justify'}}>
  <li>Μείωση κόστους συντήρησης ΚΥΤ
και ΥΣ σε βάθος χρόνου μεγαλύτερη από 20%, λόγω της μετάβασης από συντήρηση βάσει
χρονοδιαγράμματος σε συντήρηση βάσει κατάστασης του εξοπλισμού.</li>

<li>Συχνή παρακολούθηση της
κατάστασης του εξοπλισμού που οδηγεί άμεσα σε μεγαλύτερη αξιοπιστία του ηλεκτρικού δικτύου και κατά
συνέπεια μεγαλύτερη διαθεσιμότητα.</li>

<li>Μείωση των έμμεσων επιπτώσεων των διακοπών ρεύματος στην
οικονομία και την ασφάλεια.</li>

<li>Δυνατότητα διαχείρισης και παρουσίασης μεγάλου όγκου ψηφιακών
δεδομένων επιθεωρήσεων τα οποία οδηγούν στην καλύτερη κατανόηση των μηχανισμών βλάβης και
συνεπώς στην περαιτέρω βελτίωση της λειτουργίας των ΚΥΤ και των ΥΣ.</li>
</ul> 
                
               
              
              </Col>
              
            </Row>
            </Container>
            </section>
         
            <section className="section section-about-us" id='partners' >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="title" style={{color:'#0e3b5b'}}>Συνεργάτες</h2>
                <p className="description" style={{textAlign:'justify',color:'white'}}/>
                <div class="card-wrap">
  <div class="card-header four">
    <img alt='episei-logo' src={episei}></img>
  </div>
  <div class="card-content">
    <h1 class="card-title" id='up'>ΕΠΙΣΕΥ</h1>
    <p id='up' class="card-text">Το Ερευνητικό Πανεπιστημιακό Ινστιτούτο Συστημάτων Επικοινωνιών και Υπολογιστών (ΕΠΙΣΕΥ) της Σχολής Ηλεκτρολόγων Μηχανικών και Μηχανικών Υπολογιστών (ΣΗΜΜΥ) 
    του Εθνικού Μετσόβιου Πολυτεχνείου (ΕΜΠ) ιδρύθηκε το 1989.</p>
 </div>
</div>
<div class="card-wrap">
  <div class="card-header three">
    <img alt='ipto-logo' src={ipto}></img>
  </div>
  <div class="card-content">
    <h1 id='up' class="card-title">ΑΔΜΗΕ</h1>
    <p id='up' class="card-text">Ο ΑΔΜΗΕ (Ανεξάρτητος Διαχειριστής Μεταφοράς Ηλεκτρικής Ενέργειας Α.Ε. αποτελεί τον
Διαχειριστή του Ελληνικού Συστήματος Μεταφοράς Ηλεκτρικής Ενέργειας (ΕΣΜΗΕ). </p>
 </div>
</div>
                <div class="card-wrap">
  <div class="card-header one">
    <img alt='castalia-logo' src={castalia}></img>
  </div>
  <div class="card-content">
    <h1 id='up' class="card-title">Castalia</h1>
    <p id='up' class="card-text">Είμαστε μια ομάδα μηχανικών και συμβούλων καινοτομίας που συγκεντρώνει διεπιστημονικά σύνολα δεξιοτήτων στην Τεχνητή Νοημοσύνη και τις Διασυνδέσεις Ανθρώπου-Μηχανής
     με στόχο τον εξορθολογισμό της επικοινωνίας Mind-to-Machine.</p>
 </div>
</div>
<div class="card-wrap">
  <div class="card-header two">
    <img alt='twi-logo' src={twi}></img>
  </div>
  <div class="card-content">
    <h1 id='up'class="card-title">TWI hellas</h1>
    <p id='up'class="card-text">Η TWI Hellas αναπτύσσει συστηματα τεχνητής νοημοσύνης και 
    αυτοματοποίησης, που μποροούν να βοηθήσουν ανθρώπους και μηχανές σε διαφορετικούς κλάδους να αποδίδουν καλύτερα, πιο έξυπνα, πιο γρήγορα και με μεγαλύτερη ασφάλεια.</p>
 </div>
</div>


              
            </Col>
              
            </Row>
            </Container>
            </section>
     
       
        <ContactForm/>
        <DarkFooter />
        <CookieConsent
  location="bottom"
  buttonText="Agree"
  aria-label="Agree"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#ffffff",padding:'10px',position:'fixed', overflowY:'auto',borderTop:'2px solid #1c9caa',display:'block'}}
  buttonStyle={{ color:"#ffffff", fontSize: "13px",borderRadius:'5px',background:'#0a2e4b'}}
  expires={150}
>
  <h2 style={{color:'#07353d',textTransform:'none',fontSize:'1em',fontWeight:'700',marginBottom:'5px'}}>In order to provide you with the best online experience this website uses cookies.</h2>
  
  <h2 style={{color:'#07353d',textTransform:'none',fontSize:'1em',fontWeight:'700',marginBottom:'5px'}}>By using our website, you agree to our use of cookies.</h2>
    
</CookieConsent>
      </div>
    </>
  );
}

export default LandingPage;
