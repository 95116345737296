import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import vid1 from '../../photos/vid1.mp4'
import vid2 from '../../photos/vid2.mp4'
import vid3 from '../../photos/vid3.mp4'
import vid4 from '../../photos/vid4.mp4'



import DarkFooter from "../../components/Footers/DarkFooter.js";
import {ImageGrid} from "react-fb-image-video-grid"

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Gallery from "../../components/Gallery";

var settings = {
  columnCount:{
    default:3,
    mobile:3,
    tab:4
  },
  mode: 'dark'
}

function News() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='publications'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#0e3b5b',marginTop:'50px'}}>Gallery
                </h2>
                <h3>Επίσκεψη στο KYT Παλλήνης</h3>
                
              </Col>
              <Gallery/>
              <h3 className="title" style={{color:'#012335'}}>Βίντεο</h3>
              <ImageGrid >

          <video controls>
            <source
              type="video/mp4"
              src={vid3}
            />
          </video>
          <video controls>
            <source
              type="video/mp4"
              src={vid4}
            />
          </video>

 </ImageGrid>
 
            </Row>
            </Container>
            </section>
            
        <DarkFooter />
        
      </div>
    </>
  );
}

export default News;
