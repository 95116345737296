import React,{Component} from 'react'
import CheckBox  from './toggle';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import test from '../../assets/img/test2.svg'
import logo2 from '../../components/Navbars/enorasi2.png'
import { useState } from 'react'

import {
    Button,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";
  const initialState = {
    name: '',
    email: '',
    message: '',
  }
  export const ContactForm =()=>{
    const [{ name, email, message }, setState] = useState(initialState)

    const handleChange = (e) => {
      const { name, value } = e.target
      setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })
  
    const handleSubmit = (e) => {
      e.preventDefault()
      console.log(name, email, message)
      emailjs
        .sendForm(
          'service_ai424q9', 'template_dnd5yxs', e.target, 'j-QOqt3hQj_BJA3AD'
        )
        .then(
          (result) => {
            console.log(result.text)
            clearState()
          },
          (error) => {
            console.log(error.text)
          }
        )
    }

  return (
    <section id='contact' className="section section-contact-us text-center" style={{}}>
      <div class="container3">
    <div class="screen">
      <div class="screen-header">
        <div class="screen-header-left">
          <div class="screen-header-button close"></div>
          <div class="screen-header-button maximize"></div>
          <div class="screen-header-button minimize"></div>
        </div>
        <div class="screen-header-right">
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
        </div>
      </div>
      <div class="screen-body">
        <div class="screen-body-item left">
          <div class="app-title">
            <span>Επικοινωνήστε </span>
            <span>μαζί μας </span>
    
          </div>
          <br/><br/>
        <img alt='enorasi-logo' src={logo2} style={{width:'150px'}}/>
        <br/>
        <ul style={{listStyleType:'none'}}>
        <li id='social'><a target="_blank" aria-label="Linkedin" href='https://www.linkedin.com/'> <i id='social' className="fab fa-linkedin"></i></a></li>
        <li id='social'><a target="_blank" aria-label="Youtube" href='https://www.youtube.com/'> <i id='social' className="fab fa-youtube"></i></a></li>
        <li id='social'><a target="_blank" aria-label="Twitter"  href='https://www.twitter.com/'> <i id='social' className="fab fa-twitter"></i></a></li>
        </ul>
        </div>
        <form name='sentMessage' validate onSubmit={handleSubmit}>
        <div class="screen-body-item">
          <div class="app-form">
            <div class="app-form-group">
              <input class="app-form-control" placeholder="Ονομα"
                 type='text'
                 id='name'
                 value={name}
                 aria-label="Όνομα"
                 name='name'
                 required
                 onChange={handleChange}/>
              
            </div>
            <div class="app-form-group">
              <input class="app-form-control" placeholder="EMAIL"
               type='email'
               id='email'
               aria-label="Email"
               value={email}
               name='email'
               required
               onChange={handleChange}
              />
            </div>
      
            <div class="app-form-group message">
              <input class="app-form-control" placeholder="ΜΗΝΥΜΑ"
               name='message'
               id='message'
               aria-label="ΜΗΝΥΜΑ"
               rows='4'
               value={message}
               required
               onChange={handleChange}/>
            </div>
            <div class="app-form-group buttons">
  
              <button aria-label="send" class="app-form-button">Αποστολή</button>
            </div>
          </div>
        </div>
        </form>
      </div>
    </div>
   
  </div>
        </section>


);
 }
export default ContactForm